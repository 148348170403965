import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { Helmet } from 'react-helmet'

const aboutus = () => {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Auto Genius - Privacy Policy</title>
        <meta
          name="description"
          content="How we protect your privacy while being a member of the Auto Genius Slack community."
        />
      </Helmet>
      <Layout>
      
      
      <div className="max-w-5xl pt-36 md:pt-48 px-10 md:px-2 mx-auto">
        <h1 className="billrightTitle text-center text-3xl md:text-5xl font-bold mb-20">
          Privacy Policy
        </h1>
        <div className="text-black text-md b-10 max-w-4xl mx-auto terms">
        <p>Last Updated on August 15, 2021.</p>
        <h2 className="pt-8" id="intro" >1. Introduction</h2>
<p>Auto Genius Ventures LLC, a Delaware corporation d/b/a Auto Genius (“Auto Genius”) (together with its subsidiaries, the “<strong>Company</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>”) is a platform for invitation-only communities of marketing leaders in automotive.</p>
<p>This Privacy Policy (the “Policy”) provides information on how we collect, process, and share the personal information (also referred to as personal data, and referred to as “<strong>Personal Information</strong>” in this Policy) of<strong> individuals who are external to the Company</strong>, including but not limited to our Geniuses, other users of and participants in our Services or any portion thereof (including without limitation our Platform and our Events) sponsors, partners, and service providers and their respective individual representatives, as applicable (referred to in this Policy as “<strong>you</strong>” or “<strong>your</strong>”). Personal Information generally includes any information about you – an individual, natural person.</p>
<p>This Policy is incorporated in its entirety into the Company’s Terms of Service (the “<Link to="/terms">Terms</Link>”). All capitalized terms not defined in this Privacy Policy shall have the meanings set forth in the Terms.</p>
<p>After reading this Policy, if you have additional questions or would like further information, please contact us at <a href="mailto:success@autogenius.io">success@autogenius.io</a>.</p>
<h2 className="pt-12" id="personal" >2. Personal Information We Collect And How We Use It</h2>
<p>As further explained below, we collect and process Personal Information only for lawful reasons, such as when the processing is necessary to perform a contract (for example, to provide Services to our Geniuses), our legitimate business interests, such as improving, personalizing, marketing, and developing the Services and promoting safety and security, or, where required, obtaining your consent.</p>
<p id="personal_one" ><u><strong>Information We Collect From You</strong></u></p>
<p><strong>Applications, Forms, and Profile Data</strong>: If you apply for Geniuseship in one of our Communities or submit a form seeking more information about becoming a Genius, you provide all of the Personal Information requested in the application or form, including but not limited to your name, gender, email, phone, address, employment information, and other information about your professional interests, goals, background, and experience.</p>
<p><strong>Payment Data</strong>: If you are accepted for Geniuseship, we will collect Billing Information in connection with your payment of your Geniuseship Fees. Please note that we do not store your Billing Information, but the third-party payment processors we engage may retain your Billing Information in accordance with their own privacy policies and terms.</p>
<p><strong>Inquiries, Discussions, and Other Data</strong>: You may interact with us, other Geniuses or other users of the Services through our Platform, including through any interactive feature, online contact form, email, or any other communication mechanism. When using these channels, you typically provide your name, profile picture, email, phone, employment information, professional background, and any other information you choose to provide at your option.</p>
<p><strong>Event Audio and Video</strong>: We may record audio and/or video of our Geniuses, sponsors, panelists, and other participants of any of the Company’s Events for marketing purposes and also so Geniuses and others who were unable to attend the event live may watch or listen to it at a later date. We also may request that our Geniuses provide testimonials for our marketing purposes.</p>
<p><strong>No Sensitive Information</strong>: Please do <strong>not</strong> provide any highly sensitive Personal Information to us – such as social security numbers, health or medical information, racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union Geniuseship, or data concerning a person’s sex life or sexual orientation. We do not intentionally process highly sensitive Personal Information and reserve the right to reject or delete it.</p>
<p id="personal_two" ><u><strong>Information We Collect from Third Parties</strong></u></p>
<p>From time to time, we may collect Personal Information from third parties such as public websites or social networks, such as LinkedIn. This Personal Information may include names, contact information, email, professional or employment information, and other information which is publicly available.</p>
<p>We may use information collected from third parties for our own promotional or marketing purposes, such as sending prospective Geniuses emails about our Services.</p>
<p><em><strong>➔ In sum, we use data collected from you or third parties for:</strong></em></p>
<p><strong>● Geniuseship</strong>: We use your application and interview data to determine whether to grant you Geniuseship in one of our Communities.</p>
<p><strong>● Provide, Improve, and Maintain the Services</strong>: We use the data you provide to set up your account profile; provide, improve, and customize the Services; receive or make payments; and communicate with you about your Geniuseship and the Services.</p>
<p><strong>● Marketing</strong>: We may use your Personal Information to market and promote our Services. You may opt-out of marketing communications at any time by contacting us at <a href="success@autogenius.io">success@autogenius.io</a> or clicking the “Unsubscribe” button in the applicable communication.</p>
<p><strong>● Protect You and the Services</strong>: We use the information (including Personal Information) we collect to promote the safety and security of the Services, our Geniuses, and other parties. For example, we may use Personal Information to authenticate users, facilitate secure payments, protect against fraud and abuse, respond to a legal request or claim, conduct audits, or enforce our contracts and policies that may be in effect.</p>
<p id="personal_three" ><u><strong>Information We Collect Automatically</strong></u></p>
<p>We collect certain information via cookies and similar automated means when you use the Services, including when you access or use the Platform, some of which may be Personal Information. Cookies and similar technologies do not gather your unique Personal Information, like name, date of birth, gender, or email. Rather, the information collected by cookies and other automated means is generally about your devices, such as type of device, settings, frequency of usage, search history, IP address, browser used, operating system and settings, access times, and referring URL. This data may be collected by or shared with our third-party analytics partners.</p>
<p><em><strong>➔ In sum, we use data collected automatically from your devices for the following purposes:</strong></em></p>
<p>The Company and its analytics partners and service providers use the automatically collected data described above to provide and improve the Services, including to:</p>
<p>● keep track of your preferences and account information,<br/>
● customize Services and content,<br/>
● measure promotional effectiveness,<br/>
● estimate your general location and time zone, and<br/>
● secure your account</p>
<p>You have options to control or limit how we or our partners use cookies and similar technologies. For more information, please see <a href="https://www.allaboutcookies.org/cookies/" target="new" rel="nofollow"><u>www.allaboutcookies.org/cookies</u></a>.</p>
<h2 className="pt-12" id="how_we_share" >3. How We Share Your Information</h2>
<p>We share your Personal Information with the following types of third parties for the purpose of providing, improving, promoting, and securing the Services:</p>
<p>● IT service providers, like Google, to store and secure your Personal Information<br/>
● Customer resource management (CRM) tools to internally manage our Genius and prospective Genius base<br/>
● Chat platforms, like Slack, to facilitate discussions with us and among our Geniuses<br/>
● Payment processors, such as Stripe and Moonclerk, to collect your dues and process payments<br/>
● Analytics and measurement partners to help us improve, customize, and promote the Services</p>
<p>We also may share your Personal Information as required by law, to investigate potential illegal activities or violations of agreements, to protect the security of our Services and Geniuses, or as a result of a merger, acquisition, or assignment with a third party.</p>
<p id="how_we_share_two" ><strong><em>➔ A note about how we share your data with our event sponsors:</em></strong></p>
<p>If you attend one of our sponsored events, we may share your name, email, job information, and LinkedIn profile with the sponsor for their own marketing and business purposes. When required under applicable law, we will obtain your consent before sharing your data with our sponsors for their own purposes.</p>
<p>Other than the limited sharing with sponsors described above, <strong>we do not sell, rent, or lease your Personal Information to third parties or share Personal Information with third parties for their own marketing or business purposes.</strong></p>
<h2 className="pt-12" id="control" >4. Controlling Your Account and Your Rights</h2>
<p>To submit a question or concern about how to control, modify, or cancel your Geniuseship or data, contact us at <a href="mailto:success@autogenius.io">success@autogenius.io</a>. Depending on your location, you may have certain legal rights regarding your Personal Information, including:</p>
<p>● You may request access to your Personal Information and obtain information about our processing of your Personal Information, with whom we share your Personal Information, and your rights.<br/>
● You may request that we restrict our processing of your Personal Information.<br/>
● You may object to our processing of your Personal Information.<br/>
● If we process Personal Information based on your consent, you may withdraw your consent.<br/>
● You may correct Personal Information that is inaccurate or incomplete.<br/>
● You may request that we delete your Personal Information or provide a copy of it to you.</p>
<p>We may request proof of your identity before acting upon any request. To submit a question or concern about your rights, please contact us at <a href="mailto:success@autogenius.io">success@autogenius.io</a>. If you live in the EEA, UK, or Switzerland, and you are unsatisfied with our response, you have the right to lodge a complaint regarding your Personal Information with an applicable supervisory authority.</p>
<h2 className="pt-12" id="protect" >5. How We Protect and Store Your Information</h2>
<p>We maintain reasonable administrative, technical, and organizational safeguards designed to protect against loss, misuse, or unauthorized access, disclosure, alteration, or destruction of the Personal Information that we collect. We restrict access to Personal Information collected about you to our employees, contractors, and certain third-party service providers as discussed above.<strong> Please note, however, that no website or storage mechanism can guarantee 100% security. </strong></p>
<p>Generally, we store your Personal Information for as long as required to provide the Services to you, and when you cancel your Geniuseship, we take reasonable steps to delete your Personal Information within a reasonable period of time. Sometimes, we may retain your account information for a period of time after cancellation to allow you to efficiently reinstate your account, if you so choose. We also may retain certain Personal Information after you cancel your account to the extent necessary to comply with our legal and regulatory obligations, for the purpose of fraud monitoring, detection and prevention, and for our tax, accounting, and financial reporting obligations. Where we retain data, we do so in accordance with any limitation periods and records retention obligations that are imposed by applicable law.</p>
<h2 className="pt-12" id="children" >6. Children</h2>
<p>Our Services are intended for adults 18 years of age or older. We do not knowingly collect Personal Information from children under 18. If we learn that we have collected any Personal Information from a child under 18, we will take reasonable steps to delete such information. Parents or guardians who believe that their child has submitted Personal Information to us and would like to have it deleted should contact us at <a href="mailto:success@autogenius.io">success@autogenius.io</a>.</p>
<h2 className="pt-12" id="international" >7. International Transfers</h2>
<p>The Personal Information we collect within your country may be transferred outside of your country to the Company or its third-party contractors or service providers (mentioned above) for the purposes described in this Privacy Policy. As required by law, we rely on appropriate safeguards or applicable derogations, or obtain your consent, when we transfer Personal Information across international borders.</p>
<h2 className="pt-12" id="links" >8. Links to External Platforms</h2>
<p>As discussed in our <Link to="/terms-of-service">Terms</Link> we may use or link to External Platforms for a variety of purposes – such as to help us provide certain Services or to provide access to additional information and resources we think you will find helpful. Please be aware that we do not control and are not responsible for the information collection, use, and disclosure practices of such External Platforms. Please review and understand their privacy practices and policies, if any, before providing any Personal Information to them or using any of their services. We are not responsible for the content or information of any External Platform, any products or services that may be offered through any External Platforms, or any other use of any External Platforms.</p>
<h2 className="pt-12" id="updates" >9. Updates to Our Privacy Policy</h2>
<p>This Policy may be amended, modified, updated or terminated at any time by the Company, provided that the Company notifies you in advance of any material amendment, modification or update by posting an amended version of the Policy through the Platform (and the date of such posting shall be deemed the date of notification) or by notifying you of such change(s) in accordance with the notification provision included in the <a href="https://www.revenuecollective.com/terms-of-service">Terms</a>. It is your responsibility to periodically review this Policy, and you are on notice of and bound by any changes when you continue to use our Services or engage with us after you have been notified of such changes in accordance with the Terms.</p>
<h2 className="pt-12" id="contact" >10. Contact Us</h2>
<p>If you have any questions, comments, or concerns regarding this Policy or our practices please feel free to write to us at:</p>
<p className="text-center"><strong>Auto Genius Ventures LLC</strong><br/>
<strong>Attn: Data Privacy</strong><br/>
<strong>651 N Broad St</strong><br/>
<strong>Suite 205-6252</strong><br/>
<strong>Middletown, Delaware, 19709</strong><br/>
<strong><a href="mailto:success@autogenius.io">success@autogenius.io</a></strong></p>

      </div> </div>
      <hr size="1" className="mt-5 mb-5"/>
    </Layout></>
  );
};

export default aboutus;
